import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './home.scss';

class Home extends Component {
	render() {
		return (
			<div>
				<div className="home">
					<div className="home__inner">
						<h1 className="home__logo">Connect33 Pro</h1>
						<h2 className="home__desc">Connect33 Pro enables all Connect33 merchants track user redemptions of e-vouchers in real time.</h2>
					</div>
				</div>
				<div className="home__footer">
					<ul>
						<li><Link to="/privacy-policy/">Privacy Policy</Link></li>
						<li><Link to="/terms-of-use/">Terms Of Use</Link></li>
					</ul>
					<p>&copy; 2018 Mango33</p>
				</div>
			</div>
		);
	}
}

export default Home;