import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
import PrivacyPolicy from './privacy-policy';
import TermsOfUse from './terms-of-use';
import './index.css';

const App = () => (
  <Router>
    <div>
      <Route path="/" exact component={Home} />
      <Route path="/privacy-policy/" component={PrivacyPolicy} />
      <Route path="/terms-of-use/" component={TermsOfUse} />
    </div>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();