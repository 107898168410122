import React, { Component } from 'react';
import { Link } from "react-router-dom";
import HeaderSubpage from '../header-subpage';
import './terms-of-use.scss';

class TermsOfUse extends Component {
	render() {
		return (
			<div>
				<HeaderSubpage />
				<div className="terms-of-use">
					<h2 className="terms-of-use__title">Terms Of Use</h2>
					<div className="terms-of-use__inner">
						<p className="terms-of-use__p">PLEASE READ CONNECT33 PRO TERMS OF USE (THESE "TERMS OF USE") CAREFULLY. BY USING CONNECT33 PRO ON BEHALF OF A CORPORATION OR OTHER ENTITY FOR WHOM YOU WORK, YOU AND SUCH CORPORATION OR OTHER ENTITY REPRESENT AND AGREE THAT THEY ARE BOUND BY THESE TERMS OF USE AND SHALL COLLECTIVELY BE REFERRED TO AS "YOU" HEREINAFTER. IF YOU ARE NOT SO AUTHORIZED, PLEASE DO NOT USE CONNECT33 PRO. BY USING CONNECT33 PRO, YOU SHALL BE DEEMED TO HAVE ACCEPTED ALL OF THESE TERMS OF USE. IF YOU DO NOT AGREE, PLEASE DO NOT USE CONNECT33 PRO.</p>
						<p className="terms-of-use__p">Connect33 Pro is an online tool provided by Mango33 to You. Connect33 Pro has been designed to enable CONNECT33 merchants to track and validate redemptions made at merchant outlets. You hereby agree to the following terms:</p>
						<ol className="terms-of-use__list">
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Administrator Accounts</h3>
								<p className="terms-of-use__list-p">Only designated administrators have the right to access Connect33 Pro account(s). With respect to access and use of Connect33 Pro, Mango33 will confirm a completed application to be provided by Your system administrator and whether such application has been accepted or denied within approximately fifteen (15) days after receipt by Mango33 of such completed application. Mango33 may accept or deny Your application in its sole discretion. Upon Mango33’s request, You agree to provide Mango33 with any additional information necessary to confirm Your application, including but not limited to all information reasonably necessary for Mango33 to validate Your access to Connect33 Pro. Mango33, in its sole discretion, reserves the right to change any conditions necessary for access to Connect33 Pro or impose additional conditions for access to Connect33 Pro at any time.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Access to Connect33 Pro</h3>
								<p className="terms-of-use__list-p">If Mango33 accepts Your application, Mango33 will send Your account credentials to Your designated email address. You are responsible to:</p>
								<ul className="terms-of-use__list-lvl2 terms-of-use--list-lvl2-alphabet">
									<li className="terms-of-use__list-lvl2-item">
										<p className="terms-of-use__list-lvl2-p">maintain the confidentiality of Your account credentials;</p>
									</li>
									<li className="terms-of-use__list-lvl2-item">
										<p className="terms-of-use__list-lvl2-p">communicate to Mango33 and Your system administrator immediately any loss of your account credentials;</p>
									</li>
									<li className="terms-of-use__list-lvl2-item">
										<p className="terms-of-use__list-lvl2-p">alert us and your system administrator if unauthorized third parties have access to your account credentials or your account has been breached; and</p>
									</li>
									<li className="terms-of-use__list-lvl2-item">
										<p className="terms-of-use__list-lvl2-p">ensure that Your activities occurring in connection with the Admin Account(s) comply with these Terms of Use.</p>
									</li>
								</ul>
								<p className="terms-of-use__list-p">You agree that Mango33's responsibilities do not extend to the internal management or administration of the Services for You and that Mango33 is merely a data-processor and You are the data controller.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Confidentiality</h3>
								<p className="terms-of-use__list-p">You agree not to disclose to any third party any information disclosed and designated as confidential or proprietary by Mango33 without the prior written consent of Mango33, including Connect33 Pro and any materials provided to You by Mango33 through Connect33 Pro. Mango will not access nor review any information uploaded by You to Connect33 Pro unless explicitly authorized by Your system administrator in connection with a specific service request or other related activity.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">No Employment Relationship</h3>
								<p className="terms-of-use__list-p">You agree that neither Your use of Connect33 Pro, nor Your posting of any of Your information on Connect33 Pro will create an employment relationship or independent contractor relationship between You and Mango33. Nothing in these Terms of Use shall be interpreted to create any such relationship between You and Mango33.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Change and/or Termination to Connect33 Pro by Mango33</h3>
								<p className="terms-of-use__list-p">Mango33 or Your system administrator, may change the access to Connect33 Pro, or refuse or terminate the access by You at any time, with or without cause, in Mango33's or Your system administrator’s sole discretion. Your access to Connect33 Pro will be immediately terminated by Mango33 and Your system administrator, in case that You are a) in breach of these Terms of Use, b) no longer an employee of Your company, or c) a security risk. If Your access to Connect33 Pro is terminated for any reason, You agree to immediately destroy (or return if requested by Mango33) any and all materials provided previously to You by Mango33 through Connect33 Pro, including any passwords, documents, or other items. You agree not to upload to Connect33 any files, materials or information that contain malware, viruses, Trojan horses, worms, time bombs, bots or any other unlawful, harmful programs or materials, and that you do not participate in practices that interfere with the functioning of or compromise or damage the security of the Connect33 Service.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Warranty</h3>
								<p className="terms-of-use__list-p">You hereby represent and warrant that You have the right to disclose the information that You provide via Connect33 Pro. You agree to use commercially reasonable efforts to ensure that the information provided via Connect33 Pro is and remains accurate and complete. In no event shall Mango33 be liable for the accuracy of any information relating to Your company or otherwise submitted by You, and further, Mango33 will not be liable for any consequences or damage arising out of or in connection with such information and profiles.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Limitation of Liability</h3>
								<p className="terms-of-use__list-p">CONNECT33 PRO, INCLUDING ANY INFORMATION PROVIDED OVER CONNECT33 PRO BY MANGO33, IS PROVIDED "AS IS" AND MANGO33 MAKES NO WARRANTIES EXPRESS, IMPLIED OR STATUARY, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHT OF A THIRD PARTY WITH RESPECT TO ACCESS TO OR USE OF CONNECT33 PRO. IN NO EVENT SHALL MANGO33 BE LIABLE TO YOU FOR INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF BUSINESS OR ANY OTHER ECONOMIC DAMAGES PROPERTY DAMAGES OR PERSONAL INJURY AS A RESULT OF BREACH OF ANY THESE TERMS OF USE REGARDLESS OF WHETHER MANGO33 WAS ADVICED, HAD OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY THEREOF.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Disclaimer</h3>
								<p className="terms-of-use__list-p">You agree to, at Your sole cost and expense, defend, indemnify and hold Mango33, its subsidiaries, their affiliates, officers, directors, agents, and employees, harmless from any claim or demand (including reasonable attorneys’ fees) made by any third party due to or arising out of a) information provided by You, b) Your breach of these Terms of Use.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Term and Survival</h3>
								<p className="terms-of-use__list-p">Mango33 may terminate these Terms of Use at any time in its sole discretion. You may terminate these Terms of Use at any time by providing prior notice to Mango33 to hello@mango33.com The obligations and liabilities set forth in these Terms of Use shall survive any termination hereof for any reason.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Compliance with Laws</h3>
								<p className="terms-of-use__list-p">In Your use of Connect33 Pro, You agree to comply with applicable laws, and not to engage in any act that constitutes or encourages a violation of any applicable law or regulation.</p>
							</li>
							<li className="terms-of-use__list-item">
								<h3 className="terms-of-use__list-heading">Governing Law</h3>
								<p className="terms-of-use__list-p">Jurisdiction and Entire Agreement. These Terms of Use, including any documents attached or incorporated as a reference, set forth the entire agreement between Mango33 and You regarding the subject matter hereof. You acknowledge that any dispute arising out of or relating to these Terms of Use will be subject to the laws of your country. Likewise, your local jurisdiction applies.</p>
							</li>
						</ol>
					</div>
				</div>
				<div className="terms-of-use__footer">
					<ul>
						<li><Link to="/privacy-policy/">Privacy Policy</Link></li>
						<li><Link to="/terms-of-use/">Terms Of Use</Link></li>
					</ul>
					<p>&copy; 2018 Mango33</p>
				</div>
			</div>
		);
	}
}

export default TermsOfUse;