import React, { Component } from 'react';
import { Link } from "react-router-dom";
import HeaderSubpage from '../header-subpage';
import './privacy-policy.scss';

class PrivacyPolicy extends Component {
	render() {
		return (
			<div>
				<HeaderSubpage />
				<div className="privacy-policy">
					<h2 className="privacy-policy__title">Privacy Policy</h2>
					<div className="privacy-policy__inner">
						<p className="privacy-policy__p">The Connect33 Pro application (“Connect33 Pro”) is owned and operated by Mango33 Pte. Ltd. (“Mango33,” “we,” “our” or “us”). This Connect33 Pro Privacy Policy explains how we protect your privacy when you use Connect33 Pro.</p>
						<p className="privacy-policy__p">The Connect33 Pro Privacy Policy is part of the Connect33 Pro Terms of Use that govern your use of Connect33 Pro (our “Terms of Use”).</p>
						<ol className="privacy-policy__list">
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Scope of This Connect33 Pro Privacy Policy</h3>
								<p className="privacy-policy__list-p">The Connect33 Pro Privacy Policy covers the “information” and "content" we collect through Connect33 Pro. “Information” is information that identifies you personally, either alone or in combination with other information available to us. "Content" is content uploaded by you to Connect33 Pro or entered into Connect33 Pro to operate the CONNECT33 Pro service.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Your Consent</h3>
								<p className="privacy-policy__list-p">Please read this Connect33 Pro Privacy Policy carefully. If you do not agree to our Connect33 Pro Privacy Policy or our Connect33 Pro Terms of Use, you should not access Connect33 Pro.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Changes to this Connect33 Pro Privacy Policy</h3>
								<p className="privacy-policy__list-p">We reserve the right to update or revise the Connect33 Pro Privacy Policy at any time and without prior notice. These changes will be effective as of the date we post the revised version of Connect33 Pro. If you do not agree with the updated or revised Connect33 Pro Privacy Policy, you must not access Connect33 Pro.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">What Personal Information and Content Do We Collect Through Connect33 Pro?</h3>
								<p className="privacy-policy__list-p"><strong>Information and Content You Provide</strong>. We collect the personal information and content you knowingly provide when you use Connect33 Pro. The information and content we collect depends on how you use Connect33 Pro.</p>
								<p className="privacy-policy__list-p"><strong>Information and Content Sent to Us</strong>. We collect information that is sent to us automatically by your access device. This information typically includes your IP address, the identity of your Internet Service Provider, the name and version of your operating system, the name and version of your device, the date and time of your visit, and the screens you visit. An “IP address” is a unique number that is automatically assigned to your device when you connect to the Internet. It is used to identify your device’s “location” in cyberspace, so that the information you request can be delivered to you. Although it does not identify you by name, we treat your IP address and device information as your personal information.</p>
								<p className="privacy-policy__list-p">We may link the information provided by your device to information that identifies you personally. For example, when you access Connect33 Pro by clicking on a link, your device tells us that someone has clicked on a particular link. We may use this functionality to confirm that you have accessed a particular area or document posted to Connect33 Pro. In addition, we review our server logs — which contain visitors’ IP addresses — for security and fraud prevention purposes. If criminal activity is suspected, we may share our server logs and the personal information we have collected through Connect33 Pro with the appropriate investigative authorities who could use that information to trace and identify individuals.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Cookies and Similar Technologies</h3>
								<p className="privacy-policy__list-p">We use “cookies” and other Web technologies for a number of purposes, including:</p>
								<ul className="privacy-policy__list-lvl2">
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">to collect information about the ways visitors use Connect33 Pro — for example, which screens they visit, which links they use, and how long they stay on each page;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">to support the features and functionality of Connect33 Pro — for example, to save you the trouble of re-entering information already in our database or to prompt the settings you established on previous visits to Connect33 Pro; and</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">to personalize your experience when you use Connect33 Pro.</p>
									</li>
								</ul>
								<p className="privacy-policy__list-p">Sometimes the information we collect using these technologies does not identify you personally.  Other times, however, we may link the information we collect using technologies to other information that identifies you personally, including through the use of your login information.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">How Do We Use the Personal Information and Content We Collect Through Connect33 Pro?</h3>
								<p className="privacy-policy__list-p">Generally, we use the personal information and content we collect through Connect33 Pro to:</p>
								<ul className="privacy-policy__list-lvl2">
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">provide the information and services you request;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">personalize your experience when you use Connect33 Pro;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">personalize our communications with you;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">contact you with information and notices related to your use of the Connect33 Pro;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">invite you to participate in surveys and provide feedback to us;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">better understand your needs and interests, including to better understand how you are using Connect33 Pro;</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">improve the content, functionality and usability of Connect33 Pro; and</p>
									</li>
									<li className="privacy-policy__list-lvl2-item">
										<p className="privacy-policy__list-lvl2-p">support security and fraud-prevention efforts.</p>
									</li>
								</ul>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">How Do We Share Your Personal Information?</h3>
								<p className="privacy-policy__list-p"><strong>Parties That Have Access to Connect33 Pro</strong>. The primary purpose of the Connect33 Pro is to facilitate communication between You and Mango33. Thus, by posting information to Connect33 Pro, you are sharing that information with Mango33.</p>
								<p className="privacy-policy__list-p">As described above, information you knowingly provide when you use Connect33 Pro may be accessible to authorized personnel who has access to Connect33 Pro.</p>
								<p className="privacy-policy__list-p"><strong>Service Providers</strong>. We may share your personal information and content with companies that perform services on our behalf, such as Web hosting service providers. These third parties are required by contract to use the personal information and content we share with them only to perform services on our behalf and to treat your personal information and content as strictly confidential.</p>
								<p className="privacy-policy__list-p"><strong>Successors in Interest</strong>. Your personal information and content may be transferred to another company that succeeds to all or part of our business. In such event, your personal information and content will continue to be protected by this Connect33 Pro Privacy Policy and the privacy preferences you have expressed to us.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Security</h3>
								<p className="privacy-policy__list-p">We take reasonable precautions to provide a level of security appropriate to the sensitivity of the personal information we collect. Although we use reasonable measures to help protect your personal information against unauthorized use or disclosure, we cannot guarantee the security of information provided over the Internet or stored in our databases. Your transmissions are therefore at your own risk.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Supplemental Information</h3>
								<p className="privacy-policy__list-p">From time to time, we may supplement the personal information we collect through Connect33 Pro with information from other sources, including information we collect from you through other channels. For example, if you provide us with contact information, we may use that information to update the contact information collected through Connect33 Pro.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Request for Access or Correction of Personal Data</h3>
								<p className="privacy-policy__list-p">You may request to access, correct or delete the personal data currently in our possession by submitting a request in writing to our Data Protection Officer at dpo@mango33.com. If you are a CONNECT33 Pro user, you may access your personal data by logging into our Services and viewing or editing your information.</p>
								<p className="privacy-policy__list-p">For a request to access personal data, we will provide you with the relevant personal data within thirty (30) working days from such a request being made. Where a request cannot be complied with within the above time frame, we will advise you on how soon we can respond. For a request to correct personal data, we will correct your personal data as soon as practicable after the request has been made unless we have reasonable grounds not to do so.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Request to Withdraw Consent</h3>
								<p className="privacy-policy__list-p">You may withdraw your consent for the collection, use or disclosure of your personal data in our possession or under our control by submitting a request in writing to our Data Protection Officer at <a href="mailto:dpo@mango33.com">dpo@mango33.com</a>. We will process your request within thirty (30) working days from such a request for withdrawal of consent being made, and will thereafter not collect, use or disclose your personal data in the manner stated in your request. The collection of your personal data by us may be mandatory or voluntary in nature depending on the Purposes for which your personal data is collected. Where it is obligatory for you to provide us with your personal data, and you fail or choose not to provide us with such data, or do not consent to the above or this Policy, we will not be able to provide products or services or otherwise deal with you.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Complaint Process</h3>
								<p className="privacy-policy__list-p">If you have any complaint or grievance regarding about how we are handling your personal data or about how we are complying with the data protection policy, we welcome you to contact us with your complaint or grievance by writing to our Data Protection Officer at <a href="mailto:dpo@mango33.com">dpo@mango33.com</a>.</p>
								<p className="privacy-policy__list-p">We recommend you write “Data Protection Complaint” in the subject header of the email to assist us in attending to your complaint speedily. We will strive to deal with any complaint or grievance that you may have expeditiously and fairly.</p>
							</li>
							<li className="privacy-policy__list-item">
								<h3 className="privacy-policy__list-heading">Contact Us</h3>
								<p className="privacy-policy__list-p">If you have any questions about this Data Protection Policy, please do not hesitate to contact our Data Protection Officer at <a href="mailto:dpo@mango33.com">dpo@mango33.com</a>.</p>
							</li>
						</ol>
					</div>
				</div>
				<div className="privacy-policy__footer">
					<ul>
						<li><Link to="/privacy-policy/">Privacy Policy</Link></li>
						<li><Link to="/terms-of-use/">Terms Of Use</Link></li>
					</ul>
					<p>&copy; 2018 Mango33</p>
				</div>
			</div>
		);
	}
}

export default PrivacyPolicy;