import React from 'react';
import { Link } from "react-router-dom";
import './header-subpage.scss';

const HeaderSubpage = () => (
  <div className="header-subpage">
  	<h1 className="header-subpage__logo"><Link to="/" class="header-subpage__logo">Connect33 Pro</Link></h1>
  </div>
);

export default HeaderSubpage;
